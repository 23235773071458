import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssigmentService } from 'app/open-api';
import Swal from 'sweetalert2';
import { AssigmentDealerLocalModel } from 'app/shared/models/assigment-dealer.model';
import { DealersModelDetails } from 'app/open-api';
import { UpdateDispatchToDealerRQ } from 'app/open-api/model/updateDispatchToDealerRQ';
import { AddDispatchToDealerRQ } from 'app/open-api/model/addDispatchToDealerRQ';
import { PreviewDispatchResult } from "app/open-api/model/previewDispatchResult";

@Component({
  selector: 'app-dispatch-bills-preview',
  templateUrl: './dispatch-bills-preview.component.html',
  styleUrls: ['./dispatch-bills-preview.component.scss'],
  providers: [DatePipe],
})
export class DispatchBillsPreviewComponent implements OnInit {  
  @Input() operation: string;
  @Input() dispatchName: string;
  @Input() dispatchId?: string;
  @Input() dealer: DealersModelDetails;  
  @Input() allAssignments: AssigmentDealerLocalModel[] = [];
  public selectedAssignments: AssigmentDealerLocalModel[] = []; 
  page = 1;
  pageSize = 10;
  collectionSize = 0;
 
  
  constructor(public activeModal: NgbActiveModal, private datePipe: DatePipe, private spinner: NgxSpinnerService, private assigmentService: AssigmentService) { }

  ngOnInit() {
    this.collectionSize = this.allAssignments.length;
    //console.log(this.collectionSize)
  }

  get alloperations(): AssigmentDealerLocalModel[] {    
    return this.allAssignments.length > 0 ? this.allAssignments
      .map((agency, i) => ({ id: i + 1, ...agency }))
      .slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      ) : [];   
}  

GetCurrencyName(item){  
  if(item.currencyName === 'MN')
    return 'CUP';
  else return item.currencyName;
}

GetTotalPaid(item) {       
  let total = 0;    
  item.forEach((row) => {
    total += row.total;
  });  
  return total;
}

get groupedoperations() {
  return this.groupBy(
    this.allAssignments,
    "shippingTypeName"
  );
}

groupBy(data: any[], key: string) {
  return data.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
}

  AcceptModal(){
    this.spinner.show();
    if (this.dispatchId) {
      
      const request: UpdateDispatchToDealerRQ = {
        dealerId: +this.dealer.id,
        billsId: this.allAssignments.map((item) => item.billId),
        dispatchId: +this.dispatchId,
        name: this.dispatchName
      };

      this.assigmentService
        .apiUpdateAssigmentForDispatchPost(request)
        .subscribe(
          (response) => {
            if (response.status == 200) {
              this.spinner.hide();
              Swal.fire({
                title: `El despacho ha sido actualizado satistactoriamente.`,
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
                position: "top",
              });

              const result: PreviewDispatchResult = {
                status: true,
                action: 'update'
              }
              setTimeout(() => {
                this.activeModal.close(result);
              }, 3005);
            } else {
              Swal.fire({
                title: "Error! " + response.message,
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                position: "top-end",
              });
              this.spinner.hide();
            }
          },
          (error) => {
            Swal.fire({
              title: "Error! " + error,
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              position: "top-end",
            });
            this.spinner.hide();
          }
        );
    } else {
      const request: AddDispatchToDealerRQ = {
        dealerId: this.dealer.id,
        billsId: this.allAssignments.map((item) => item.billId),
        name: this.dispatchName
      };

      this.assigmentService.apiAddAssigmentForDispatchPost(request).subscribe(
        (response) => {
          if (response.status == 200) {
            this.spinner.hide();
            Swal.fire({
              title: `El despacho ha sido creado satistactoriamente.`,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              position: "top",
            });
            const result: PreviewDispatchResult = {
              status: true,
              action: 'add'
            }
            setTimeout(() => {
              this.activeModal.close(result);
            }, 3005);
          } else {
            Swal.fire({
              title: "Error! " + response.message,
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
              position: "top-end",
            });
            this.spinner.hide();
          }
        },
        (error) => {
          Swal.fire({
            title: "Error! " + error,
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
            position: "top-end",
          });
          this.spinner.hide();
        }
      );
    }
  }
  

}
