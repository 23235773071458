<div class="modal-header">
  <h2 class="modal-title" id="myModalLabel1">Vista previa <b>{{ operation }}</b> del despacho <b> {{ dispatchName }} </b> para el repartidor <b>{{ dealer.userName }}</b>  </h2>
  <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
  <div class="row">
    <div class="col">
    <div *ngFor="let shippingType of (groupedoperations | keyvalue)">
     <table class="table table-striped pt-2">
       <thead>
        <tr>
          <th scope="col" colspan="7">
            <label> {{ shippingType.key }}</label>
          </th>
        </tr>
         <tr>           
           <th class="text-center" scope="col">Código</th>
           <th class="text-center" scope="col">Vuelo</th>
           <th class="text-center" scope="col">Municipio</th>    
           <th class="text-center" scope="col">Provincia</th>
           <th class="text-center" scope="col">Peso</th>
           <th class="text-center" scope="col">Moneda</th>     
           <th class="text-center" scope="col">Total</th>             
         </tr>
         <tr *ngIf="groupedoperations?.length === 0">
           <th class="text-center" scope="col" colspan="7">
             <label>No existen facturas en el despacho</label>
           </th>
         </tr>
       </thead>
       <tbody>
         <tr *ngFor="let bill of shippingType.value">           
           <td class="text-center">{{ bill?.billCode}}</td>
           <td class="text-center">{{ bill?.flightCode}}</td>
           <td class="text-center">{{ bill?.billCustomerModel.City.Name}}</td>
           <td class="text-center">{{ bill?.billCustomerModel.City.State.Name}}</td>
           <td class="text-center">{{ bill?.pounds | number: '1.2-2'}}</td>   
           <td class="text-center">{{ GetCurrencyName(bill)}}</td> 
           <td class="text-center">{{ bill?.total | currency}}</td>                     
         </tr>
       </tbody>
       <tfoot>
        <tr>
          <td scope="col" colspan="5">
            <div class="d-flex align-items-center justify-content-start pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
              <div style="font-weight: bold; padding-right: 2px;">Total de facturas: </div> 
              <span style="padding-right: 2px;">{{ shippingType.value.length }}</span> | 
              <div style="font-weight: bold; padding-right: 2px; padding-left: 2px">Total a pagar: </div>
              <span style="padding-right: 2px;">{{GetTotalPaid(shippingType.value) | number: '1.2-2'}}</span> |
              <div style="font-weight: bold; padding-right: 2px; padding-left: 2px"> {{GetCurrencyName(shippingType.value[0])}} </div>     
              
            </div>
          </td>
        </tr>
      </tfoot>
     </table>
     </div>     
    </div>
   </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end align-items-center">
    <button type="button" class="btn btn-primary px-2 mx-2" rippleEffect (click)="AcceptModal()"
        [disabled]="allAssignments.length == 0">Aceptar</button>
    <button type="button" class="btn btn-danger" rippleEffect (click)="activeModal.close()">Cancelar</button>
  </div>
</div>

