import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShippingListModel } from 'app/open-api';
import { ShippingType, shippingTypes } from '../../../open-api/model/shippingType';

@Component({
  selector: "app-create-shipping-modal",
  templateUrl: "./create-shipping-modal.component.html",
  styleUrls: ["./create-shipping-modal.component.scss"],
})
export class CreateShippingModalComponent implements OnInit {
  @Input() shipping?: ShippingListModel;
  shippingForms: FormGroup;
  date = new Date();
  today = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  shippingTypes: Array<ShippingType> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {
    this.shippingTypes = shippingTypes;
  }

  ngOnInit(): void {    
    if (this.shipping) {
      this.date = new Date(this.shipping.createAt);
      this.today = {
        year: this.date.getFullYear(),
        month: this.date.getMonth() + 1,
        day: this.date.getDate(),
      };
    }
    this.shippingForms = this.formBuilder.group({      
      code: [this.shipping ? this.shipping.code : null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(10),
        Validators.pattern(/^[a-zA-Z\s0-9]+$/)
      ])],
      shippingType: [this.shipping ? this.shipping.shippingType : null, Validators.required],
      description: [
        this.shipping ? this.shipping.description : null,
        Validators.compose([
          Validators.required,         
          Validators.maxLength(60),
        ])       
      ],   
      createAt: [this.today, Validators.required],
    });
  }

  get f() {
    return this.shippingForms.controls;
  }

  AcceptModal() {
    const day = this.f.createAt.value.day;
    const month = this.f.createAt.value.month;
    const year = this.f.createAt.value.year;
    const date = new Date(year, month - 1, day);
    this.f.createAt.setValue(date);
    if (this.shipping) {
      this.shipping.code = this.f.code.value;
      this.shipping.description = this.f.description.value;
      this.shipping.shippingType = this.f.shippingType.value;
      this.shipping.createAt = date;
      this.activeModal.close(this.shipping);
    }
    this.activeModal.close(this.shippingForms.value);
  }
}
