<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">{{shipping ? 'Editar' : 'Crear'}} Envío </h4>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
    <form [formGroup]="shippingForms">
        <div class="form-group">
            <label class="form-label" for="code-input">
                <b class="text-primary">*</b> Código: 
            </label>
            <input type="text" class="form-control" maxlength="8" formControlName="code" aria-describedby="code-input" autofocus=""
                tabindex="1" [ngClass]="{ 'is-invalid': f.code.errors && (f.code.touched || f.code.dirty) }" />
            <div *ngIf="f.code.errors && (f.code.touched || f.code.dirty)" class="invalid-feedback">
                <div *ngIf="f.code.errors.required">El código es requerido.</div>
            </div>
            <div *ngIf="f.code.errors && (f.code.touched || f.code.dirty)" class="invalid-feedback">
                <div *ngIf="f.code.errors.pattern">Error de formato. Solo letras y números.</div>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label" for="shippingType-input">
                <b class="text-primary">*</b> Tipo de envío: 
            </label>
            <ng-select formControlName="shippingType" aria-describedby="shippingType-input" [items]="shippingTypes" bindLabel="name" bindValue="id" placeholder="Tipos de envío"
             notFoundText="No hay información">
            </ng-select>           
        </div>
        <div class="form-group">
            <label class="form-label" for="desciption-input">
                <b class="text-primary">*</b> Descripción: 
            </label>
            <input type="text" class="form-control" formControlName="description" aria-describedby="desciption-input"
                [ngClass]="{ 'is-invalid': f.description.errors && (f.description.touched || f.description.dirty) }" />
            <div *ngIf="f.description.errors && (f.description.touched || f.description.dirty)"
                class="invalid-feedback">
                <div *ngIf="f.description.errors.required">La descripción es requerida.</div>
            </div>
            <div *ngIf="f.description.errors && (f.description.touched || f.description.dirty)"
                class="invalid-feedback">
                <div *ngIf="f.description.errors.maxlength">Solo se aceptan hasta 60 caracteres.</div>
            </div>
        </div>
        <div class="form-group">
            <label class="form-label" for="fecha-input">
                <b class="text-primary">*</b> Fecha: 
            </label>
            <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="Fecha del Envio" formControlName="createAt"
                    ngbDatepicker #basicDP="ngbDatepicker" aria-describedby="date-input"
                    [ngClass]="{ 'is-invalid': f.createAt.errors && (f.createAt.touched || f.createAt.dirty) }"
                    readonly />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                        type="button" rippleEffect></button>
                </div>
            </div>
            <div *ngIf="f.createAt.errors && (f.createAt.touched || f.createAt.dirty)" class="invalid-feedback">
                <div *ngIf="f.createAt.errors.required">La fecha es requerida.</div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="AcceptModal()" rippleEffect
        [disabled]="shippingForms.invalid">
        {{shipping ? 'Editar' : 'Crear'}}
    </button>
</div>